import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useTranslation } from 'react-i18next'

import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"
import PageHeader from "@components/blocks/common/PageHeader"
import NewsList from "@components/blocks/news/List"
import CategoryList from "@components/blocks/news/CategoryList"
import Pagination from "@components/blocks/news/Pagination"

import css from "@css/components/blocks/common/Page.module.styl"

export default function Posts({data, pageContext,...props})
{
		const {t} = useTranslation()
		const newsData = data.allWordpressPost.edges
		console.log(data,pageContext)
		return (
				<React.Fragment>
						<SEO title={'NEWS'} />
						<div className={css.container}>
								<PageHeader l1="NEWS" l2={t('news__headerTitle2')}/>
								<div className={css.content}>
										<div className={css.categoryList}>
												<CategoryList/>
										</div>
										<NewsList data={newsData} thumbnail_brank={data.thumbnail_brank}/>
										<div className={css.pagination}>
												<Pagination pageContext={pageContext}/>
										</div>
								</div>
						</div>
						<FooterSns/>
						<Footer/>
				</React.Fragment>
		)
}

	//<Layout>
		//<PostList posts={posts} title="Latest posts" />
		//<Pagination pageContext={pageContext} pathPrefix="/" />
	//</Layout>

export const pageQuery = graphql`
	query PostQuery($limit: Int!, $skip: Int!) {
		allWordpressPost(
			sort: { fields: date, order: DESC }
			limit: $limit
			skip: $skip
		) {
			edges {
			node {
				...PostListFields
			}
			}
		}
		thumbnail_brank : file(relativePath: { eq: "Common__img__thumbnail-brank@2x.png" }) {
			childImageSharp { fluid(maxWidth: 640) {
				...GatsbyImageSharpFluid
			} }
			publicURL
	  	}
	}
`
